import React, { createContext, useState } from 'react';
import { ThreeCircles, ThreeDots } from "react-loader-spinner";
import GetImage from '../assets/GetImage';


interface LoaderContextType {
    isLoading: boolean;
    showLoader: (customMessage?: string) => void;
    hideLoader: () => void;
}

export const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

export const LoaderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>();

    const showLoader = (customMessage?: string) => {
        setMessage(customMessage || '');
        setIsLoading(true);
    };
    const hideLoader = () => setIsLoading(false);

    return (
        <LoaderContext.Provider value={{ isLoading, showLoader, hideLoader }}>
            {children}
            {isLoading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    {/* <ThreeCircles
                        visible={isLoading}
                        height="100"
                        width="100"
                        color="#825EFF"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /> */}
                    <GetImage imageName="LoaderGif" />
                    <p style={{ color: 'white', marginTop: '10px', fontSize: '18px', flexDirection: 'column' }}>
                        {message}
                    </p>
                    <ThreeDots
                        visible={true}
                        height="8"
                        width="50"
                        color="#ffffff"
                        radius="3"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
        </LoaderContext.Provider>
    );
};
