import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const goToBusinessPage = () => {
    navigate("/business/schedule");
  };

  return (
    <div className="w-full h-[500px] flex flex-col justify-center items-center ">
      {/* <img src="/paymentDone.png" alt="payment" className="w-[100px]" /> */}
      <img
        src="https://res.cloudinary.com/djoz0tmyl/image/upload/v1722526982/paymentDone_xtvutf.png"
        alt="payment"
        className="w-[100px]"
      />
      <h4 className="mt-3 font-semibold text-green-600">Payment Done!</h4>
      {/* <p className="mt-1 text-base font-medium text-center text-gray-700">
      Thank you for completing your secure online payment. Have a great
      day!
    </p> */}

      <p className="mt-3 text-xl font-semibold text-center text-yellow-700">
        Your establishment has been published!
      </p>

      <button
        className="px-2 py-2 mt-5 font-semibold text-gray-600 bg-transparent border-2 border-green-600 rounded"
        onClick={goToBusinessPage}
      >
        Go Back
      </button>
    </div>
  );
};

export default PaymentSuccess;
