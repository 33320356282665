import React, { Children } from "react";
import LandingPage from "../pages/LandingPage.js";
import PageNotFound from "../pages/PageNotFound";
import { FilterProvider } from "../features/Business/FilterContext.tsx";
import { Bus, Component } from "lucide-react";
import PaymentSuccess from "../components/PaymentSuccess.js";
const Admin = React.lazy(() => import("./../pages/AdminPage"));
const Places = React.lazy(() => import("./../pages/Places"));
const Bookings = React.lazy(() => import("./../pages/Bookings"));
const Login = React.lazy(() => import("./../pages/LoginPage"));
const LoginPassword = React.lazy(() => import("./../pages/LoginPasswordPage"));
const Register = React.lazy(() => import("./../pages/RegisterPage"));
const EnterOtp = React.lazy(() => import("./../pages/EnterOtpPage"));
const ForgotPassword = React.lazy(() =>
  import("./../pages/ForgotPasswordPage")
);
const ResetLink = React.lazy(() => import("./../pages/ResetLinkPage"));
const PasswordChanged = React.lazy(() =>
  import("./../pages/PasswordChangedPage.tsx")
);

const Thankyou = React.lazy(() => import("./../pages/ThankyouPage"));
const UserProfile = React.lazy(() => import("./../pages/UserPage.tsx"));
const Search = React.lazy(() => import("./../pages/SearchPage"));
const SalonProfile = React.lazy(() =>
  import("./../pages/SearchDetailsPage.jsx")
);
const ServiceDialog = React.lazy(() =>
  import("./../features/ServiceDialog/ServiceDialog.jsx")
);

const Reschedule = React.lazy("../features/ServiceDialog/Reschedule.jsx");
const MyFavorites = React.lazy(() =>
  import("./../features/MyFavorites/MyFavorites")
);

const BusinessLanding = React.lazy(() =>
  import("./../features/Business/BusinessHomePage/BusinessHomePage.jsx")
);

const BusinessLayout = React.lazy(() =>
  import("./../pages/business/BusinessLayout.jsx")
);

const BusinessHome = React.lazy(() =>
  import("./../pages/business/BusinessHomePage.tsx")
);

const BusinessSchedule = React.lazy(() =>
  import("./../pages/business/BusinessSchedulePage.tsx")
);
const BusinessPublish = React.lazy(() =>
  import("./../pages/business/BusinessPublishPage.tsx")
);
const BusinessTeams = React.lazy(() =>
  import("./../pages/business/BusinessTeamsPage.tsx")
);
const BusinessClients = React.lazy(() =>
  import("./../pages/business/BusinessClientsPage.tsx")
);
const BusinessAppointments = React.lazy(() =>
  import("./../pages/business/BusinessAppointmentPage.tsx")
);
const BusinessSalonProfile = React.lazy(() =>
  import("./../pages/business/BusinessSalonProfilePage.tsx")
);
const BusinessServices = React.lazy(() =>
  import("./../pages/business/BusinessServicesPage.tsx")
);

const BusinessAnalytics = React.lazy(() =>
  import("./../pages/business/BusinessAnalyticsPage.tsx")
);
const BusinessAccount = React.lazy(() =>
  import("./../pages/business/BusinessAccountPage.tsx")
);
const BusinessPreview = React.lazy(() =>
  import("./../pages/business/BusinessPreviewPage.tsx")
);
const BusinessLogin = React.lazy(() =>
  import("./../pages/BusinessLoginPage.js")
);
const BusinessRegister = React.lazy(() =>
  import("./../pages/BusinessRegisterPage.jsx")
);
const BusinessForgotPassword = React.lazy(() =>
  import("./../pages/BusinessForgotPasswordPage.js")
);

const OnBoardingSteps = React.lazy(() =>
  import("./../features/Business/SalonProfile/Onboarding/OnBoardingSteps.tsx")
);

export const routes = [
  {
    name: "Landing",
    path: "/",
    exact: true,
    element: <LandingPage />,
  },
  {
    name: "Admin",
    path: "/admin",
    exact: true,
    element: <Admin />,
  },
  {
    name: "Bookings",
    path: "/admin/bookings",
    exact: true,
    element: <Bookings />,
  },
  {
    name: "Places",
    path: "/places",
    exact: true,
    element: <Places />,
  },
  {
    name: "Login",
    path: "/login",
    exact: true,
    element: <Login />,
  },
  {
    name: "LoginPassword",
    path: "/loginPassword",
    exact: true,
    element: <LoginPassword />,
  },
  {
    name: "Register",
    path: "/register",
    exact: true,
    element: <Register />,
  },
  {
    name: "Otp",
    path: "/otp",
    exact: true,
    element: <EnterOtp />,
  },
  {
    name: "ForgotPassword",
    path: "/forgotPassword/:resetPasswordToken",
    exact: true,
    element: <ForgotPassword />,
  },
  {
    name: "ForgotPassword",
    path: "/forgotPassword",
    exact: true,
    element: <ForgotPassword />,
  },
  {
    name: "Resetlink",
    path: "/resetLink",
    exact: true,
    element: <ResetLink />,
  },
  {
    name: "PasswordChanged",
    path: "/passwordChanged",
    exact: true,
    element: <PasswordChanged />,
  },
  {
    name: "Thankyou",
    path: "/thankyou",
    exact: true,
    element: <Thankyou />,
  },
  {
    name: "UserProfile",
    path: "/userprofile",
    exact: true,
    element: <UserProfile />,
  },

  {
    name: "Search",
    path: "/search",
    exact: true,
    element: <Search />,
  },
  {
    name: "SalonProfile",
    path: "/salon/:estId",
    exact: true,
    element: <SalonProfile />,
  },
  {
    name: "ServiceProfile",
    path: "/salon/:estId/service",
    exact: true,
    element: <ServiceDialog />,
  },

  {
    name: "Reschedule",
    path: "/salon/:estId/reschedule",
    exact: true,
    element: <Reschedule />,
  },
  {
    name: "Favorites",
    path: "/favourites",
    exact: true,
    element: <MyFavorites />,
  },
  {
    name: "BusinessLanding",
    path: "/business",
    element: <BusinessLanding />,
  },

  {
    name: "BusinessLayout",
    path: "/business",
    element: <BusinessLayout />,
    children: [
      {
        name: "BusinessHome",
        path: "home",
        element: <BusinessHome />,
      },
      {
        name: "BusinessDashboard",
        path: "/business/schedule",
        exact: true,
        element: <BusinessSchedule />,
      },
      {
        path: "/business/schedule/payment-success",
        exact: true,
        element: <PaymentSuccess />,
      },
      {
        name: "BusinessPublish",
        path: "publish",
        element: <BusinessPublish />,
      },
      {
        name: "BusinessTeams",
        path: "teams",
        element: <BusinessTeams />,
      },
      {
        name: "BusinessClients",
        path: "clients",
        element: <BusinessClients />,
      },

      {
        name: "BusinessAppointments",
        path: "appointments",
        element: <BusinessAppointments />,
      },
      {
        name: "BusinessSalonProfile",
        path: "salonProfile",
        element: <BusinessSalonProfile />,
      },
      {
        name: "BusinessServices",
        path: "services",
        element: <BusinessServices />,
      },
      {
        name: "BusinessAnalytics",
        path: "analytics",
        element: <BusinessAnalytics />,
      },
      {
        name: "BusinessAccount",
        path: "account",
        element: <BusinessAccount />,
      },
    ],
  },
  {
    name: "BusinessPreview",
    path: "/business/preview/:estId",
    exact: true,
    element: <BusinessPreview />,
  },

  {
    name: "BusinessLogin",
    path: "/business/login",
    exact: true,
    element: <BusinessLogin />,
  },
  {
    name: "BusinessRegister",
    path: "/business/register",
    exact: true,
    element: <BusinessRegister />,
  },
  {
    name: "BusinessForgotPassword",
    path: "/business/forgotPassword",
    exact: true,
    element: <BusinessForgotPassword />,
  },
  {
    name: "OnBoardingSteps",
    path: "/business/getStarted",
    exact: true,
    element: <OnBoardingSteps />,
  },

  {
    name: "PageNotFound",
    path: "*",
    exact: true,
    element: <PageNotFound />,
  },

  //   {
  //   name: "BusinessLogin",
  //   path: "/business/log",
  //   exact: true, element:
  // },
  // {
  //   name: "BusinessRegister",
  //   path: "/business/register",
  //   exact: true, element:
  // },
  // {
  //   name: "BusinessForgotPassword",
  //   path: "/business/forgotPassword",
  //   exact: true, element:
  // },
];
